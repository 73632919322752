@use '../variables';

body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

::-moz-selection {
  color: $white;
  background: map-get($color-brand, 'primary');
}

::selection {
  color: $white;
  background: map-get($color-brand, 'primary');
}

hr {
  margin: 2rem 0;
  border: 2px dashed map-get($color-gray, 'lightest');
}

// Reset styles outside of the app content
a {
  text-decoration: none;
}

main a {
  text-decoration: underline;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

main ul, ol {
  list-style-position: outside;
  padding-left: 1.125rem;
  margin-bottom: var(--bs-gutter-y);

  &:last-child {
    margin-bottom: 0;
  }
}

main section {
  h2 {
    margin-bottom: 1.5rem;
    border-bottom: 1px map-get(variables.$color-brand, 'primary') solid;
  }

  h3 {
    margin-top: 2rem;
    font-size: calc(1rem + .3vw);
  }
}

// Utility to replicate the logo for any text: Red-Text Black-Text
// <span>Ruby<span>Conf
.text-branded {
  color: variables.$base-font-color;
  font-weight: bold;
  text-decoration: none;

  > span {
    color: map-get(variables.$color-brand, 'primary');
  }
}
