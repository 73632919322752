.error {
  margin-top: 100px;

  hr {
    width: 250px;
    height: 1px;
    margin: 35px 0;
  
    border: none;
  }

  &__headline {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -1px;
    margin-bottom: 5px;
  }

  &__sub-header {
    margin: 0;
    font-size: 30px;
    line-height: 46px;
  }

  &__context {
    font-size: 16px;
    line-height:27px;
  }

  &__action {
    display: flex;

    margin-top: 30px;
    margin-bottom:30px;
  }

  &__action a {
    display: inline-block;
    padding: 8px 30px 9px;
  
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
  
    border: 2px solid $primary;
    border-radius: 99px;

    &:not(:last-child) {
      margin-right:25px;
    }

    @media screen and (max-width: 768px) {
      padding: 8px 12px 9px;
    }
  }


  .btn.btn--outline {
    color: $primary;
    background: transparent;
    border-color: $primary;
  }
}
  